import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { jwtDecode } from 'jwt-decode';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-associated-upi-application',
  templateUrl: './associated-upi-application.component.html',
  styleUrls: ['./associated-upi-application.component.scss']
})
export class AssociatedUpiApplicationComponent {
  @Input() inputData: any;
  @Output() backToParent = new EventEmitter();
  associatedForm!: FormGroup;
  currentUser: any;
  lists: any[] = [];
  page = 1;
  pageSize = 10;
  startIndex = 0;
  endIndex = 9;
  totalRecords = 0;
  fullList: any[] = [];
  filteredList: any[] = [];
  collectionSize = 0;
  isNewUpi: boolean = false;
  checkingData: any = {};
  submitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    public applicationService: ApplicationService,
    private sessionService: SessionService,
    private utilService: UtilService,
  ) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;


  }
  closeList() {
    this.isNewUpi = !this.isNewUpi;
  }


  ngOnInit(): void {
    console.log(this.inputData);
    this.associatedForm = this.fb.group({
      upi: ['', Validators.required],
      projectId: [this.inputData.applicationId, Validators.required],
      userId: [this.currentUser.userId, Validators.required],
      isRRAVerified: [],
      ownerFullName: [''],
      ownerIdNo: [''],
      plotSize: ['']
    });
    this.loadList();

  }

  ngOnDestroy() {
    this.applicationService.searchTerm = '';
  }

  loadList() {
    this.lists = [];
    // + this.inputData.applicationId
    // this.applicationService.findAllWithPath(environment.applicationUrl + 'application/associatedUPI'
    // this.applicationService.findAllWithPath(environment.applicationUrl + 'application/associatedUPI/ByApplication/' + this.inputData.applicationId
    this.applicationService.findAllWithPath(environment.applicationUrl + 'application/associatedUPI/ByProject/' + this.inputData.applicationId).subscribe(
      dataItem => {
        this.lists = dataItem;
        this.inputData.combiningPlotSize = 0;
        this.lists.forEach(element => {
          this.inputData.combiningPlotSize =
            (this.inputData.combiningPlotSize + (+element.plotSize));
        });
      }
    )
  }

  removeUpi(event: any) {
    this.applicationService.deleteWithPath(event.id, environment.applicationUrl + 'application/associatedUPI')
      .subscribe(
        data => {
          this.loadList();
        })
  }

  getPremiumData() {
    this.filterAndPaginate();
  }

  filterAndPaginate() {
    const filtered = this.fullList.filter(item => this.applyFilter(item));
    this.filteredList = filtered;
    this.totalRecords = filtered.length;
    this.startIndex = (this.page - 1) * this.pageSize + 1;
    this.endIndex = (this.page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }
    this.lists = filtered.slice(this.startIndex - 1, this.endIndex);
  }
  onSearchTermChange(term: string): void {
    this.applicationService.searchTerm = term;
    this.filterAndPaginate();
  }

  applyFilter(item: any): boolean {
    if (!this.applicationService.searchTerm) return true;
    const term = this.applicationService.searchTerm.toLowerCase();
    return Object.values(item).some(val =>
      String(val).toLowerCase().includes(term)
    );
  }

  cancel() {
    this.backToParent.emit(this.inputData.combiningPlotSize);
  }




  onSubmit() {

    // checkRRA
    this.submitted = true;
    this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/rra?upi=' + this.associatedForm.value.upi)
      .subscribe(
        data => {
          this.checkingData.label = "";
          if (data && data.message === 'Server Down') {

            // this.checkIfUPIIsExistInOldSystem();
            this.checkUPIInLIS();
          } else if (data && data.ResponseObject && data.ResponseObject.StatusCode === 1) {
            // this.rraData.isRRAVerified = true;
            this.associatedForm.controls['isRRAVerified'].setValue(true);
            // this.checkIfUPIIsExistInOldSystem();
            this.checkUPIInLIS();
          } else {
            this.submitted = false;
            // this.rraData.isError = true
            this.associatedForm.controls['isRRAVerified'].setValue(false);
            this.utilService.showNotification(NOTIFICATION_COLOR.error, "This plot has some unpaid taxes", "bottom", "center");
          }
        }, error => {
          this.checkingData.label = "";
          this.submitted = false;
          // this.checkIfUPIIsExistInOldSystem();
        }
      )
  }


  checkUPIInLIS() {
    this.checkingData.label = "Checking UPI in Land";
    this.applicationService.findAllWithPath(environment.landAPI + this.associatedForm.value.upi)
      .subscribe(
        data => {
          console.log(data.data);
          if (data.data.isUnderRestriction) {
            this.utilService.showNotification(NOTIFICATION_COLOR.warning, "In the checking process, the UPI is under restriction.", " top", "right");
          } else if ((data.data.owners.length > 0) && 
          (data.data.owners[0].idTypeName === "NATIONAL ID") 
            && !this.findNationalIdIsInUpiOwnersList(data.data.owners)) {
              this.utilService.showNotification(NOTIFICATION_COLOR.warning, "In the checking process, the UPI is under restriction.", " top", "right");

          } else if ((data.data.owners.length > 0) && (data.data.owners[0].idNo) &&
          this.findNationalIdIsInUpiOwnersList(data.data.owners)) {
            console.log(data.data);
            this.associatedForm.controls['ownerFullName'].setValue(data.data.owners[0].fullName)
            this.associatedForm.controls['ownerIdNo'].setValue(data.data.owners[0].idNo)
            this.associatedForm.controls['plotSize'].setValue(data.data.size)
            this.saveAssociatedUPI()
          }

        }, error => {
          this.submitted = false;
          this.checkingData.label = "";
        }
      )
  }


  findNationalIdIsInUpiOwnersList(owners: any) {
    //  if (data.data.owners[0].idNo && (data.data.owners[0].idNo.replace(/\s+/g, "")) !== (this.currentUser.data.user.nationalId.replace(/\s+/g, "")
    // )
    let value = owners.filter((x: any) => x.idNo.replace(/\s+/g, "") === this.currentUser.data.user.nationalId.replace(/\s+/g, ""));
    console.log(value);
    if (value.length > 0) {
      return true;
    } else {
      return false;
    }
  }


  saveAssociatedUPI() {
    this.applicationService.saveAssetWithPath(this.associatedForm.value, environment.applicationUrl + 'application/associatedUPI')
      .subscribe(
        data => {
          this.submitted = false;
          // this.cancel();
          this.closeList()
          this.loadList();
        }, error => {
          this.submitted = false;
        })
  }
}
